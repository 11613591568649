import React from 'react';
import { useTour } from '@reactour/tour';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import SusyDarkMode from './assets/img/wanna/susy/susy-dark-mode.png';
import Susy8 from './assets/img/wanna/susy/susy8.png';
import Susy9 from './assets/img/wanna/susy/susy9.png';
import Susy10 from './assets/img/wanna/susy/susy10.png';
import Button from './components/bootstrap/Button';

/**
 * Steps style
 */
export const styles = {
	/**
	 * Overlay style
	 * @param base
	 */
	maskWrapper: (base: any) => ({ ...base }),
	maskArea: (base: any) => ({
		...base,
		rx: 'var(--bs-border-radius)',
	}),
	highlightedArea: (base: any) => ({ ...base }),
	badge: (base: any) => ({ ...base }),
	popover: (base: any) => ({
		...base,
		boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)',
		backgroundColor: 'var(--bs-body-bg)',
		color: 'var(--bs-body-color)',
		borderRadius: 'var(--bs-border-radius)',
	}),
};

/**
 * Image Styles
 * @type {(data?: {theme?: DefaultTheme}) => Classes<"image">}
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '150px',
		objectFit: 'contain',
	}
});

/**
 * Prev & Next buttons
 * @returns {JSX.Element}
 * @constructor
 */
const TourNavigation = () => {
	const { currentStep, setCurrentStep } = useTour();
	return (
		<div className='col-12 mt-3'>
			<div className='row'>
				<div className='col'>
					{!!currentStep && (
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							Prev
						</Button>
					)}
				</div>
				<div className='col-auto'>
					<Button
						icon='ArrowForwardIos'
						color='info'
						isLight
						onClick={() => setCurrentStep(currentStep + 1)}>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};

/**
 * Selector
 * @param name
 * @returns {`[data-tour='${string}']`}
 */
const getTargetName = (name: string): `[data-tour='${string}']` => {
	return `[data-tour='${name}']`;
};

const DarkModeTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={SusyDarkMode} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Dark / Light Mode</p>
					<p>You can switch between dark and light mode with this toggle.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const VersionTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-5'>
				<img src={Susy8} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-7 d-flex align-items-center'>
				<div>
					<p className='lead'>Version</p>
					<p>You can view upcoming, current or past version documentation using the version selection.</p>
				</div>
			</div>
			<div className='col-12 mt-3'>
				<div className='row'>
					<div className='col-auto'>
						Each version relates to a version of Service Bus Manager itself.
					</div>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const DocTour = () => {
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy9} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Documentation</p>
					<p>Toggle which kind of documentation you would like to view. Features view provides a high level introduction to Service Bus Management. Documentation view provides technical details.</p>
				</div>
			</div>
			<TourNavigation />
		</div>
	);
};

const LastTour = () => {
	const { setIsOpen, currentStep, setCurrentStep } = useTour();
	const classes = useStyles();
	return (
		<div className='row'>
			<div className='col-md-4'>
				<img src={Susy10} className={classes.image} width='100%' alt='' />
			</div>
			<div className='col-md-8 d-flex align-items-center'>
				<div>
					<p className='lead'>Thank you for following along!</p>
					<p>Now, explore the features and the available documentation using the menu.</p>
					<p>And, don't forget the toggle at the bottom of the menu to change between each.</p>
				</div>
			</div>
			<div className='col-12 mt-3'>
				<div className='row'>
					<div className='col-auto'>
						Thanks for visiting MQVision.com.au and please remember to visit us in the 
						<Link to='https://azuremarketplace.microsoft.com/en-us/marketplace/apps/DSS.service-bus-management?ocid=initialcampaign_' target="_blank">Azure Marketplace</Link> under 
						Service Bus Management.
					</div>
				</div>
				<div className='row'>
					<div className='col'>
						<Button
							icon='ArrowBackIos'
							color='info'
							isLink
							onClick={() => setCurrentStep(currentStep - 1)}>
							Prev
						</Button>
					</div>
					<div className='col-auto'>
						<Button
							icon='Close'
							color='success'
							isLink
							onClick={() => {
								setIsOpen(false);
								setCurrentStep(0);
							}}>
							Close
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

/**
 * Tour Steps
 */
const siteSteps = [
	/**
	 * Dark Mode
	 * @step 0
	 */
	{
		selector: getTargetName('dark-mode'),
		content: () => <DarkModeTour />,
	},
	{
		selector: getTargetName('version-tour'),
		content: () => <VersionTour />,
	},
	/**
	 * Documentation
	 * @step 1
	 */
	{
		selector: getTargetName('documentation'),
		content: () => <DocTour />,
		highlightedSelectors: ['#aside-menu-two'],
		mutationObservables: ['#aside-menu-two', '#aside-demo-pages', '.aside-body'],
		resizeObservables: ['#aside-menu-two', '#aside-demo-pages', '.aside-body'],
	},
	/**
	 * Last
	 * @step 22
	 */
	{
		selector: '[href="/"]',
		content: () => <LastTour />,
	},
];

export default siteSteps;
